import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import type { ExtractArrayType } from '@zento-lib/util/types';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { Format } from '@zento-lib/components/i18n/inline';

import { NotificationStore } from '../notification/notification';

import { fetchForms, FetchFormsQuery, submitForms, fetchFormsLocation } from './operations.graphql';

@Store
export class CustomFormsStore extends BaseStore {
  /**
   * Custom form data keeper
   */
  @Field
  private formData: FetchFormsQuery['forms'];

  /**
   * Custom form checkout location keeper
   */
  @Field
  private fieldsCheckoutLocation: any;

  /**
   * Custom form account location keeper
   */
  @Field
  private fieldsAccountLocation: any;

  /**
   * Determines fields values location keeper
   */
  @Field
  fieldsLocationValues: {};

  /**
   * Determines loading upload image
   */
  @Field
  loader: boolean;

  /**
   * Determines form data inputs
   */
  @Field
  formInputs: any;

  private notificationStore = new NotificationStore();

  /**
   * Fetch form
   */
  public async fetchCustomForms(formId: number) {
    let forms: FetchFormsQuery = { forms: [] };

    try {
      forms = await this.dataSources.graphQl.queue({
        ...fetchForms,
        params: { formId, storeId: currentStoreView().storeId },
      });
    } catch (e) {
      console.error('Forms Store (fetchCustomForms): ', e);
    }

    this.formData = [...(this.formData || []), ...forms.forms];

    return this.formData;
  }

  /**
   * Fetch forms location
   */
  public async fetchCustomFormLocation(location: string) {
    let forms: FetchFormsQuery = { forms: [] };

    try {
      forms = await this.dataSources.graphQl.queue({
        ...fetchFormsLocation,
        params: { location },
      });
    } catch (e) {
      console.error('Forms Store (fetchCustomForms): ', e);
    }

    const formFields = (forms.forms[0] || {}) as any;

    if ('location' in formFields) {
      const preciseFieldsLocation = JSON.parse(formFields.fields);

      if (formFields.location === 'checkout') {
        Object.assign(formFields, {
          beforePersonalDetails: [],
          afterPersonalDetails: [],
          beforeShipping: [],
          betweenShipping: [],
          afterShipping: [],
          beforeBilling: [],
          betweenBilling: [],
          afterBilling: [],
          beforeProducts: [],
          betweenProductsAcknowledgements: [],
          afterAcknowledgements: [],
        });

        preciseFieldsLocation.map((f) => {
          switch (f.precise_location_checkout) {
            case 'before_personal_details':
              formFields.beforePersonalDetails.push(f);
              break;
            case 'after_personal_details':
              formFields.afterPersonalDetails.push(f);
              break;
            case 'before_shipping':
              formFields.beforeShipping.push(f);
              break;
            case 'between_shipping':
              formFields.betweenShipping.push(f);
              break;
            case 'after_shipping':
              formFields.afterShipping.push(f);
              break;
            case 'before_billing':
              formFields.beforeBilling.push(f);
              break;
            case 'between_billing':
              formFields.betweenBilling.push(f);
              break;
            case 'after_billing':
              formFields.afterBilling.push(f);
              break;
            case 'before_products':
              formFields.beforeProducts.push(f);
              break;
            case 'between_products_acknowledgements':
              formFields.betweenProductsAcknowledgements.push(f);
              break;
            case 'after_acknowledgements':
              formFields.afterAcknowledgements.push(f);
              break;
          }
        });

        this.fieldsCheckoutLocation = formFields;

        return this.fieldsCheckoutLocation;
      } else {
        Object.assign(formFields, {
          beforeAccount: [],
          afterAccount: [],
        });

        preciseFieldsLocation.map((f) => {
          switch (f.precise_location_customer_registration) {
            case 'before_account':
              formFields.beforeAccount.push(f);
              break;
            case 'after_account':
              formFields.afterAccount.push(f);
              break;
          }
        });

        this.fieldsAccountLocation = formFields;

        return this.fieldsAccountLocation;
      }
    }

    return formFields;
  }

  /**
   * Submit form
   */
  public async send(formContent: string, formTitle: string, formId: number, showSuccessMessage: boolean) {
    return this.mutate(async () => {
      try {
        const resp = await this.dataSources.graphQl.queue({
          ...submitForms,
          params: { input: { formContent, formTitle, formId } },
        });

        if (resp.zentoFormSubmit) {
          this.fieldsLocationValues = {};

          if (showSuccessMessage) {
            this.notificationStore.spawnNotification({
              message: Format.message(resp.zentoFormSubmit.success_message),
              action1: { action: () => 'close' },
            });
          }
        }
      } catch (e) {
        console.error('Forms Store (send): ', e);
      }
    });
  }

  /**
   * Determines payment order status
   */
  public async formImageUploadFile(preSignedPost, inputs: any) {
    this.loader = true;

    const formData = new FormData();

    formData.append('acl', preSignedPost.acl);
    formData.append('X-Amz-Credential', preSignedPost.xAmzCredential);
    formData.append('X-Amz-Algorithm', preSignedPost.xAmzAlgorithm);
    formData.append('X-Amz-Date', preSignedPost.xAmzDate);
    formData.append('Policy', preSignedPost.policy);
    formData.append('X-Amz-Signature', preSignedPost.xAmzSignature);
    formData.append('X-Amz-Security-Token', preSignedPost.xAmzSecurityToken);

    inputs.forEach((i) => {
      if (i.type === 'file') {
        Array.from(i.files).forEach((f: any) => {
          formData.append('key', 'zentoform_fileupload/tmp/' + f.name);
          formData.append('file', f);
        });
      }
    });

    try {
      await fetch(preSignedPost.action, {
        method: preSignedPost.method,
        body: formData,
      }).then((res) => {
        if (res.ok) {
          this.loader = false;
        }
      });
    } catch (err) {
      console.error('[Upload form image to S3]', err);
    }
  }

  /**
   * Retrieve specific form data by it's identifier
   */
  public getFormByIdentifier(identifier: number): ExtractArrayType<FetchFormsQuery['forms']> | null {
    return this.formsMap[identifier] || null;
  }

  /**
   * Compute a map of forms identifier to forms values in order to allow fast selection
   */
  private get formsMap() {
    return (this.formData || []).reduce((ret, i) => ({ ...ret, [i.formId]: i }), {});
  }

  /**
   * Determines fields checkout location
   */
  public get checkoutLocation() {
    return this.fieldsCheckoutLocation || {};
  }

  /**
   * Determines fields account location
   */
  public get accountLocation() {
    return this.fieldsAccountLocation || {};
  }

  /**
   * Determines fields location
   */
  public get fieldsLocation() {
    return this.fieldsLocationValues || {};
  }

  /**
   * Set fields location
   */
  public set fieldsLocation(value: {}) {
    const values = Object.assign(this.fieldsLocation, value);
    const content = Object.keys(values).reduce(
      (ret, i) => ({
        ...ret,
        [i.replace(/\./g, ' ')]: values[i],
      }),
      {},
    );

    this.fieldsLocationValues = content;
  }

  /**
   * Determines form data inputs
   */
  public get formDataInputs() {
    return this.formInputs || [];
  }

  /**
   * Set form data inputs
   */
  public set formDataInputs(value: any) {
    this.formInputs = value;
  }

  /**
   * Determines loader upload image
   */
  public get loaderImage() {
    return this.loader || false;
  }
}
